import { ROOT_ELEMENT_ID } from './constants';

const init = async (): Promise<void> => {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID);
  if (!rootElement) {
    throw new Error(`Element #${ROOT_ELEMENT_ID} was not found!`);
  }

  const appRender = await import('./AppRender');
  await appRender.render(rootElement);
};

init().catch((error) => {
  throw error;
});
