import { Team } from '@ori/testid-generator';
import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import type { Revalidator, RevalidatorOptions, SWRConfiguration } from 'swr';

import type { ApiError } from './models/ApiError';

export const axiosClient = applyCaseMiddleware(axios.create(), {
  // preserve keys in translations that represent locales, e.g. en-GB, ka-GE
  preservedKeys: (input) => /^[A-Za-z]{2}-[A-Za-z]{2}$/.test(input),
});

/**
 * User id for anonymous user.
 */
export const NOT_LOGGED_IN_USER_ID = -1;

/**
 * Identity server client ID for authentication.
 */
export const IDENTITY_CLIENT_ID = 'SPADev';

/**
 * Name of this app.
 */
export const APP_NAME = 'ui-prototype';

/**
 * Team which owns this SPA.
 */
export const APP_TEAM_NAME: Team = Team.Framework;

/**
 * Serves as identifier to the root of this SPA in the HTML:
 */
export const ROOT_ELEMENT_ID = 'ui-prototype-root';

/**
 * Header lang param
 */
export const ACCEPT_LANGUAGE_HEADER_KEY = 'accept-language';

/**
 * Initial report base object (does not contain valid data)
 */
export const REPORT_BASE = {
  reportIdentifier: '',
  periodId: -1,
};

/**
 * Type for column in DND
 */
export const DndItemTypes = {
  COLUMN: 'column',
};

/**
 * SWR config
 */
export const swrConfig: SWRConfiguration = {
  errorRetryCount: 3,
  errorRetryInterval: 10000,
  focusThrottleInterval: 300000,
  shouldRetryOnError: true,
  suspense: false,
  /* eslint-disable max-params -- library requires too many parameters */
  onErrorRetry: (
    error: ApiError,
    key: string,
    config: SWRConfiguration,
    revalidate: Revalidator,
    { retryCount }: RevalidatorOptions,
  ) => {
    const statusCode = Number(error.data.status ?? 200);

    if (statusCode >= 400 && statusCode < 500) {
      return;
    }

    const rCount = retryCount ?? 1;

    if (rCount >= (config.errorRetryCount ?? 0)) {
      return;
    }

    setTimeout(() => void revalidate({ retryCount: rCount }), config.errorRetryInterval);
  },
  /* eslint-enable max-params */
};
